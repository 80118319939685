import React from 'react';
import { useControllers } from '../../../controllers/app.controller';
import { shuffle } from '../../../scripts/helpers/random';
import ProductCard from '../../ProductCard/ProductCard';
import PageSectionDiscoverHeader from './PageSectionDiscoverHeader';
import HorizontalSwiper from '../../HorizontalSwiper/HorizontalSwiper';
import PageSection from '../../PageSection/PageSection';
import { Observer } from 'mobx-react-lite';
import { LinkTagConfig } from '../../../types/appContentConfig.types';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';
import useAllWpProduct from '../../../content/AllWpProduct/AllWpProduct';
import './PageSectionDiscover.scss';

type PageSectionDiscoverProps = {
  row1Categories: string[] | null,
  row2Categories: string[] | null,
  header?: {
    showAsCard?: boolean,
    text: string,
    button: LinkTagConfig,
  }
  productConfig?: {
    canViewDetails?: boolean,
    hideDetails?: boolean,
    linkFactory?: (slug: string) => string,
  }
}

const PageSectionDiscover: React.FC<PageSectionDiscoverProps> = props => {

  const { UI } = useControllers();

  const productsQueried = useAllWpProduct();;

  const p = props;
  const s = {
    get data() {
      const products = shuffle(productsQueried);
      const row1Categories = p.row1Categories;
      const row2Categories = p.row2Categories;
      return {
        row1: row1Categories ? products.filter(p => p.categories.nodes.find(c => row1Categories.includes(c.slug))) : null,
        row2: row2Categories ? products.filter(p => p.categories.nodes.find(c => row2Categories.includes(c.slug))) : null,
      }
    },
  }

  console.log(p);
  return <Observer children={() => (<PageSection id="SectionDiscover" className="PageSectionDiscover page-section section-discover" observeVisibility>
    {p.header && !p.header.showAsCard && <PageSectionHeader
      className="PageSectionDiscoverHeader container"
      text={p.header.text}
      buttonConfig={p.header.button}
    />}
    {p.header?.showAsCard && UI.displayMode === 'phone' && <PageSectionDiscoverHeader className="ProductCard-slider-item ProductCard-slider-header" />}
    {s.data.row1 && <HorizontalSwiper>
      {p.header?.showAsCard && UI.displayMode !== 'phone' && <PageSectionDiscoverHeader className="ProductCard-slider-item ProductCard-slider-header" />}
      {s.data.row1.map(product => (
        <React.Fragment key={product.id}>
          <ProductCard product={product} {...p.productConfig} />
        </React.Fragment>
      ))}
    </HorizontalSwiper>}
    {s.data.row2 && <HorizontalSwiper>
      {s.data.row2.map(product => (
        <React.Fragment key={product.id}>
          <ProductCard product={product} {...p.productConfig} />
        </React.Fragment>
      ))}
    </HorizontalSwiper>}
  </PageSection>)} />
}

export default PageSectionDiscover;