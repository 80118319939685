import './PageSectionDiscoverHeader.scss';

import React from 'react';

import APP_CONTENT_CONFIG from '../../../constants/appContentConfig.constants';
import joinClassNames from '../../../utils/className.utils';
import BaseButton from '../../BaseButton/BaseButton';
import BaseIcon from '../../BaseIcon/BaseIcon';
import BaseLink from '../../BaseLink/BaseLink';

type PageSectionDiscoverHeaderProps = {
  className?: string,
}

const PageSectionDiscoverHeader: React.FC<PageSectionDiscoverHeaderProps> = props => {
  const p = props;
  const { header } = APP_CONTENT_CONFIG.page.frontPage.sectionDiscover;
  return <header className={joinClassNames("section-discover-header", p.className)}>
    {header.Icon}
    <h2>{header.title}</h2>
    <BaseLink to={header.button.href}>
      <BaseButton className="PageSectionDiscoverHeaderButton">
        <BaseIcon icon={header.button.icon} size={header.button.iconSize} />
        <span>{header.button.Label}</span>
      </BaseButton>
    </BaseLink>
  </header>
}

export default PageSectionDiscoverHeader;