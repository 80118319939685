export function shuffleInPlace(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function shuffle<T>(array: T[]): T[] {
  return shuffleInPlace([...array]);
}

export function getRandomInteger(floor = 0, ceil = 10) {
  return Math.round(Math.random() * (ceil - floor) + floor);
}