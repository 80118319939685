import React from 'react';
import { LinkTagConfig } from '../../types/appContentConfig.types';
import joinClassNames from '../../utils/className.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseIcon from '../BaseIcon/BaseIcon';
import BaseLink from '../BaseLink/BaseLink';
import './PageSectionHeader.scss';

type PageSectionHeaderProps = {
  className?: string,
  text?: string,
  buttonConfig?: LinkTagConfig,
}

const PageSectionHeader: React.FC<PageSectionHeaderProps> = props => {
  const p = props;
  return (p.text || p.buttonConfig?.Label) ? <div className={joinClassNames("PageSectionHeader", p.className)}>
    {p.text && <h2>{p.text}</h2>}
    {p.buttonConfig && <BaseLink to={p.buttonConfig.href}>
      <BaseButton className="PageSectionHeaderButton">
        <BaseIcon icon={p.buttonConfig.icon} size={p.buttonConfig.iconSize} />
        <span>{p.buttonConfig.Label}</span>
      </BaseButton>
    </BaseLink>}
  </div> : null;
}

export default PageSectionHeader;